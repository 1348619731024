import { FC, useEffect, useRef, useState } from 'react'
import styles from './NewStandard.module.scss'
import { NewStandardProps } from './types'
import { getDefaultBenefits } from './utils'
import { Icon } from 'components/Phantom/Icon'
import { VideoGif } from 'components/basic/VideoGif'
import { Button } from 'components/Phantom/Button'
import { FullScreenModal } from 'components/Phantom/_sections/ThisIsPod4Ultra'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import cx from 'classnames'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { amClickedButton } from 'events/amplitude'

export const NewStandardConnected = observer(({ ctaUrl, afterIntelligentSystem, anchorHref }: NewStandardProps) => {
	const { metricRegion } = useRootStore().settingsStore
	return (
		<NewStandard
			ctaUrl={ctaUrl}
			metric={metricRegion}
			afterIntelligentSystem={afterIntelligentSystem}
			anchorHref={anchorHref}
		/>
	)
})

export const NewStandard: FC<NewStandardProps> = (props) => {
	const { benefits = getDefaultBenefits(), metric = false, ctaUrl = '/product/pod-cover' } = props
	const [videoVisible, setVideoVisible] = useState(false)

	const videoSource = metric ? 'https://d115sb6i1ixllw.cloudfront.net/assets/new-standard-celsius.mp4' : 'https://d115sb6i1ixllw.cloudfront.net/assets/GE-2342%20Pod%204%20Ultra.mp4'

	const cta = props.anchorHref ? (
		<AnchorLink
			offset={50}
			href={props.anchorHref}
			className={styles.cta}
		>
			<Button.Dark
				id={'new-standard-cta'}
				className={styles.cta}
				onClick={() => {
					amClickedButton('Vanity new stadard CTA', 'new-standard-cta')
				}}
			>
				Shop now
			</Button.Dark>
		</AnchorLink>
	) : (
		<Button.Dark
			id={'new-standard-cta'}
			href={ctaUrl}
			className={styles.cta}
		>
			Shop now
		</Button.Dark>
	)

	return (
		<div className={cx(styles.container, { [styles.container_product_breakdown]: props.afterIntelligentSystem })}>
			<header>
				<p>New</p>
				<h2>Pod 4 Ultra is the new standard in sleep</h2>
				{cta}
			</header>

			<div className={styles.video_container}>
				<VideoGif src={videoSource} />

				<div className={styles.cta_container}>
					<Button.Empty
						onClick={() => setVideoVisible(true)}
						id={'new-standard-watch-video'}
						className={styles.video_button}
					>
						<Icon
							name={'PlayDark'}
							color={'white'}
						/>
						Watch the video
					</Button.Empty>
				</div>
			</div>

			<ul className={styles.benefits}>
				{benefits.map((benefit, index) => (
					<li key={index}>
						<Icon
							name={benefit.icon}
							color={'black'}
						/>
						<p>{benefit.text}</p>
					</li>
				))}
			</ul>

			<FullScreenModal
				closeAction={() => {
					setVideoVisible(false)
				}}
				visible={videoVisible}
				aspectRatio={'16/9'}
			>
				<VideoPlayer
					metric={metric}
					forcePlaying={videoVisible}
				/>
			</FullScreenModal>
		</div>
	)
}

export const VideoPlayer = (props: { forcePlaying: boolean; metric: boolean }) => {
	const videoRef = useRef<HTMLVideoElement>(null)

	useEffect(() => {
		if (videoRef.current) {
			if (props.forcePlaying) {
				videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
		}
	}, [props.forcePlaying])

	return (
		<div className={styles.videoContainer}>
			<video
				ref={videoRef}
				src={props.metric ? 'https://d115sb6i1ixllw.cloudfront.net/assets/pod_4_launch_metric_subtitles.mp4' : 'https://d115sb6i1ixllw.cloudfront.net/assets/Pod%204%20launch%20with%20subtitles.mp4'}
				preload={'none'}
				autoPlay={false}
				loop={false}
				controls
			/>
		</div>
	)
}
